import { render, staticRenderFns } from "./ProductImages.vue?vue&type=template&id=4dc2cdfb&scoped=true"
import script from "./ProductImages.vue?vue&type=script&lang=js"
export * from "./ProductImages.vue?vue&type=script&lang=js"
import style0 from "@@/core/components/product/ProductImages/ProductImagesStyle.css?vue&type=style&index=0&id=4dc2cdfb&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc2cdfb",
  null
  
)

export default component.exports